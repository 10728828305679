<template>
  <div class=" px-5">
    <add-company
      @updated="
        (_) => {
          addCompany = _;
          if (!_) getComp();
        }
      "
      :dialog="addCompany"
    ></add-company>
    <v-row>
      <v-col cols="12" md="12">
        <v-card outlined :loading="loading" class="transparent">
          <v-card-title class="text-h4 font-weight-bold mb-2">
            {{ $t("companies") }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('click-on-company-card-to-manage-it-and-see-its-details') }}
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <template v-if="loading">
                <v-col v-for="i in 6" :key="i" cols="12" md="4">
                  <v-skeleton-loader light type="card"></v-skeleton-loader>
                </v-col>
              </template>
              <template v-if="!loading">
                <template v-if="companies.length > 0">
                  <v-col
                    v-for="company in companies"
                    :key="company.id"
                    cols="12"
                    @click="openCompany(company)"
                    md="4"
                    lg="4"
                  >
                    <v-card
                      class="my-card position-relative rounded py-5 px-3"
                    >
                      <img
                        width="75"
                        class="mb-1 rounded"
                        v-if="company.logoUrl !== null"
                        :src="company.logoUrl"
                        alt=""
                      />
                      <h2 class="mb-3">
                        {{ company.name }}
                      </h2>
                      <ul class="list-unstyled">
                        <li class="bg-light px-2 py-1 mb-1">
                          {{ $t('all-users-count') }} : {{ company.allUsersCount }}
                        </li>
                        <li class="bg-dark px-2 py-1 mb-1">
                          {{ $t('managers-count') }} : {{ company.managersCount }}
                        </li>
                        <li class="bg-light px-2 py-1 mb-1">
                          {{ $t('supervisors-count') }} : {{ company.supervisorsCount }}
                        </li>
                        <li class="bg-light px-2 py-1 mb-1">
                          {{ $t('invoices-count') }} : {{ company.invoicesCount }}
                        </li>
                        <li class="bg-light px-2 py-1 mb-1">
                          {{ $t('medical-representative-count') }} :
                          {{ company.medicalRepresentativeCount }}
                        </li>
                        <li class="bg-light px-2 py-1 mb-1">
                          {{ $t('medical-teamleaders-count') }} :
                          {{ company.medicalTeamleadersCount }}
                        </li>
                        <li class="bg-light px-2 py-1 mb-1">
                          {{ $t('sales-representative-count') }} :
                          {{ company.salesRepresentativeCount }}
                        </li>
                        <li class="bg-light px-2 py-1 mb-1">
                          {{ $t('sales-teamleaders-count') }} :
                          {{ company.salesTeamleadersCount }}
                        </li>
                        <li class="bg-light px-2 py-1 mb-1">
                          {{ $t('subscription-price') }} : {{ company.subscriptionPrice }}
                        </li>
                        <li class="bg-light px-2 py-1 mb-1">
                          {{ $t('totalamounts-received') }} :
                          {{ company.totalAmountsReceived }}
                        </li>
                        <li class="bg-light px-2 py-1 mb-1">
                          {{ $t('warehuode-count') }} : {{ company.warehuodeCount }}
                        </li>
                      </ul>
                    </v-card>

                  </v-col>
                </template>
                <div v-else class="d-flex align-center justify-center">
                  <h1>No Companies Found</h1>
                </div>
              </template>
              <v-col v-if="!true" cols="12" md="4">
                <v-card
                  @click="addCompany = !addCompany"
                  style="height: 100%; background-color: transparent"
                  flat
                  class="cursor-pointer"
                >
                  <v-card-text
                    class="
                      d-flex
                      justify-center
                      flex-column
                      align-content-center
                    "
                  >
                    <v-icon size="100" color=""> mdi-plus-circle </v-icon>
                    <span
                      class="
                        text-center text--white text-h5
                        font-weight-bold
                        mt-5
                      "
                    >
                      Add a new company
                    </span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.my-card {
  /* background: #fff; */
  padding: 35px 22px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  cursor: pointer;
}
.my-card img {
  border: 3px solid #efefef;
}

.my-card ul {
  list-style-type: none;
  padding: 0px !important;
}

.my-card:not(.theme--dark) ul li {
  background-color: #efefef;
}
.my-card:not(.theme--light) ul li {
  background-color: #979797;
}
</style>
<script>
import AddCompany from "./AddCompany.vue";

export default {
  components: {
    AddCompany,
  },
  data() {
    return {
      addCompany: false,
      addManager: false,
      companies: [],
      managers: [],
      loading: true,
    };
  },
  methods: {
    openCompany(com) {
      this.$store.commit("UPDATE_COMPANY", com);
      this.$router.push({ name: "dashboard-company" });
    },
    getComp() {
      this.loading = true;
      this.$http
        .get("/Companies", {})
        .then((res) => {
          this.companies = res.data.result;
          this.loading = false;
        })
        .catch(() => {
          this.$store.commit("UPDATE_SNACKBAR", true);
        });
    },
  },
  computed: {
    userData(company) {
      if (company.manager != undefined) {
        return company.manager;
      } else {
        return company.id;
      }
    },
  },
  created() {
    this.getComp();
  },
};
</script>
